
const testimonials = [
    {
      name: "Emily",
      testimony: "As an AASW accredited Social Worker with a decade of international experience in addressing domestic violence and sexual assault, I specialize in safety planning. My approach is trauma-informed and holistic, aiming to assist survivors in their journey from crisis to thriving. Recognising a significant gap in addressing survivors' digital security, I've created eGuardian for professionals and survivors ultimately contributing to the overall well-being and empowerment of individuals and professionals.",
      imageSrc: "/images/Emily.jpeg",
    }
  ];
  
  export default testimonials;
  