import "./components/css/home.css";
import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import demo from "./components/images/demo.png"; // Replace with your image path

function Home() {
  return (
    <Container>
      <Row className="mb-5">
        <Col md={7}>
          <Row>
            <h1 className="custom-text-primary my-5 title">
              Securing online lives,
              <br />
              Protecting real ones
            </h1>
          </Row>
          <Row>
            <p className="custom-text-primary my-2 fs-5">
              A safety plan without eGuardian is like locking all your doors but
              leaving the windows wide open. You've taken physical precautions,
              but what about your digital safety?
            </p>
          </Row>
          <Button variant="primary" className="fs-5 px-4 py-2 me-3 my-5" target="_blank" href="https://pinnate-conga-8dd.notion.site/eGuardian-6f8fe3d61973466681e6055fbc9cf265">
            To eGuardian
          </Button>
          <Button variant="secondary" className="fs-5 px-4 py-2 ms-3 my-5" href="#help">
            Learn More{" "}
          </Button>
        </Col>
        <Col md={5} className="d-flex justify-content-center align-items-center">
          <Image src={demo} alt="Demo Images" width="100%" height="auto" />
        </Col>
      </Row>
    </Container>
  );
}

export default Home;
