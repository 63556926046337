import "./components/css/home.css";
import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import typing from "./components/images/typing.png";

function About() {
  return (
    <Container className="py-5">
      <h1 className="text-white my-5">About</h1>
      <Row>
        <Col md={5}>
          <Image src={typing} alt="Demo Images" width="100%" height="auto" />
        </Col>
        <Col md={7}>
          <p className="text-white fs-4 fw-lighter">
            With the click of a button, abusers can access your location,
            communications, and personal information, putting you and your loved
            ones at risk. For victims of domestic abuse, online privacy is more
            than a convenience - it's a matter of safety and survival. That's
            why our organisation is dedicated to helping victims take back
            control of their digital lives for full 360 safeguarding from abuse.
            Access the free online safety resource now with simple How To’s on
            protecting yourself online and erasing your digital footprint from
            abusers.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default About;
