import React from "react";
import ReactDOM from "react-dom/client";
import "./components/css/custom-bootstrap.scss";
import Home from "./home";
import Navigation from "./nav/Navbar";
import Footer from "./nav/footer";
import { BrowserRouter } from "react-router-dom";
import About from "./about";
import Support from "./support";
import Help from "./help";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <div className="main">
      <Navigation />
      <div className="mt-5">
        <Home />
      </div>
      <div className="bg-custom-primary">
        <About />
      </div>
      <Support />
      <Help />
      <Footer />
    </div>
  </BrowserRouter>
);
