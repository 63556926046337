import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import designerLogo from "../components/images/Clever_Robin.png";
import "../components/css/footer.css";

function Footer() {
  return (
    <>
      <footer className="text-md-center py-2 text-center bg-custom-primary">
        <Container>
          <Row>
            <Col xs={12} md={12} className="text-md-center">
              <div className="mt-2"></div>
              <a
                href="https://clever-robin.com/solutions/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={designerLogo}
                  alt="Designer Logo"
                  className="designer-logo mb-3"
                />
              </a>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default Footer;
