import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import testimonials from "./components/testimonials";
import "./components/css/testimonials.css";

function Support() {
  return (
    <Container className="py-5">
      <h1 className="custom-text-primary my-5">Supported by Professionals</h1>
      {testimonials.map((testimonial, index) => (
        <Row className={`testimonial ${index % 2 === 0 ? "even" : "odd"} mb-5`}>
          <Col
            md={3}
            className={`image-col ${
              index % 2 === 0 ? "order-md-1" : "order-md-2"
            }`}
          >
            <Image
              src={testimonial.imageSrc} // Relative to the public directory
              alt={testimonial.name}
              width="100%"
              height="auto"
              className="rounded rounded-5"
            />
          </Col>
          <Col
            md={9}
            className={`info-col ${
              index % 2 === 0 ? "order-md-2" : "order-md-1"
            }`}
          >
            <div className="name">{testimonial.name}</div>
            <div className="speech-bubble p-5 w-100">
              <p className="my-auto fs-5">"{testimonial.testimony}"</p>
            </div>
          </Col>
        </Row>
      ))}
    </Container>
  );
}

export default Support;
