import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import "./components/css/help.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullseye, faGift, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

function Help() {
  return (
    <Container className="py-5">
      <h1 className="custom-text-primary my-5 text-center" id="help">How We Help</h1>
      <Row className="mb-5">
        <Col md={4}>
          <div className="glow-container">
            <FontAwesomeIcon icon={faBullseye} size="2x" />
            <h2 className="glow-title custom-text-primary">Our Mission</h2>
            <p className="glow-text">
              Our purpose is simple yet profound: to make digital safety as
              indispensable as a locked door. We believe that everyone deserves
              to rebuild their lives without fear, both in the physical and
              digital worlds.
            </p>
          </div>
        </Col>
        <Col md={4}>
          <div className="glow-container">
          <FontAwesomeIcon icon={faGift} size="2x" />
            <h2 className="glow-title custom-text-primary">What We Offer</h2>
            <p className="glow-text">
              eGuardian is a haven for victims and social workers alike. We
              provide insights, tips, and actionable advice to safeguard your
              digital presence. You don't need to be a cybersecurity expert;
              we've got you covered.
            </p>
          </div>
        </Col>
        <Col md={4}>
          <div className="glow-container">
          <FontAwesomeIcon icon={faQuestionCircle} size="2x"/>
            <h2 className="glow-title custom-text-primary">Why Us?</h2>
            <p className="glow-text">
            eGuardian is the only place bringing the experience of tireless social workers together with Cyber Security professionals to offer everyone full 360 safeguarding against domestic violence and abuse no matter your circumstances.
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mx-auto text-center">
          <Button variant="primary" className="fs-5 px-4 py-2" target="_blank" href="https://pinnate-conga-8dd.notion.site/eGuardian-6f8fe3d61973466681e6055fbc9cf265">
            To eGuardian
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default Help;
