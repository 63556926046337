import React from "react";
import { Navbar, Nav, Image, Button, Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import companyLogo from "../components/images/shield.png"; // Replace with your image path

function CustomNavbar() {
  const location = useLocation();

  return (
    <Navbar variant="light">
      <Container fluid>
        <Navbar.Brand as={Link} to="/info">
          <Image src={companyLogo} alt="Designer Logo" width="42" height="42" />
          eGuardian
        </Navbar.Brand>
        <Nav className="ml-auto">
          {" "}
          <Button variant="primary" className="fs-6 px-4 py-2 me-3 my-5" target="_blank" href="https://pinnate-conga-8dd.notion.site/eGuardian-6f8fe3d61973466681e6055fbc9cf265">
            To eGuardian
          </Button>
        </Nav>
      </Container>
    </Navbar>
  );
}

export default CustomNavbar;
